import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';

class PricingTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { monthly: true };
  }

  render() {
    const { pricingTable } = this.props;
    const { monthly } = this.state;
    return (
      <>
        <div className="columns pricing-table">
          {pricingTable.map(
            ({
              title,
              price,
              monthly_price,
              yearly_price,
              yearly_price_per_month,
              items,
            }) => (
              <div className="column is-4">
                <div className="pricing-plan">
                  <p className="has-text-centered is-size-5 has-text-weight-bold is-uppercase plan-header">
                    {title}
                  </p>
                  <p className="has-text-centered is-size-4 plan-price has-text-weight-bold has-text-primary">
                    {price || (
                      <>
                        {monthly ? (
                          <>{monthly_price} / kk</>
                        ) : (
                          <>{yearly_price} / v</>
                        )}{' '}
                      </>
                    )}
                  </p>
                  {!monthly && yearly_price_per_month && (
                    <p className="has-text-centered is-size-7 is-uppercase has-text-weight-semibold">
                      vain {yearly_price_per_month} / kk!
                    </p>
                  )}
                  <ul className="plan-items">
                    {items.map((item) => (
                      <li className="plan-item">{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )
          )}
        </div>
        <div class="content">
          <div class="buttons has-addons is-centered">
            <button
              className={`button is-light ${!monthly ? 'is-outlined' : ''}`}
              onClick={() => this.setState({ monthly: true }) || true}
            >
              Kuukausi
            </button>
            <button
              className={`button is-light ${monthly ? 'is-outlined' : ''}`}
              onClick={() => this.setState({ monthly: false }) || true}
            >
              Vuosi
            </button>
          </div>
        </div>
      </>
    );
  }
}

const PricingTemplate = ({
  title,
  pricingTable,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section">
      <h2 className="title">{title}</h2>
      <PricingTable pricingTable={pricingTable} />
      <PageContent className="content" content={content} />
    </section>
  );
};

const Pricing = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <PricingTemplate
        contentComponent={HTMLContent}
        pricingTable={frontmatter.pricing_table}
        title={frontmatter.title}
        content={html}
      />
    </Layout>
  );
};

Pricing.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default Pricing;

export const pricingQuery = graphql`
  query Pricing($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        pricing_table {
          title
          price
          monthly_price
          yearly_price
          yearly_price_per_month
          items
        }
      }
    }
  }
`;
